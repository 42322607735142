import React from "react";
import { render } from "react-dom";

import App from './App.jsx'

const headerElement = document.getElementById("wu-header")
const home_url = headerElement.dataset.url || '/'
const site_name = headerElement.dataset.name || 'Testing site'

render(<App home_url={home_url} site_name={site_name} />, headerElement);
