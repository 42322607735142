import React from "react";
import { Transition } from "react-transition-group";
import gsap from "gsap";

const TransitionHeight = props => (
  <Transition
    timeout={500}
    in={props.isExpanded}
    onEnter={
      ( node, done ) => {
        gsap.set(
          node,
          {
            height: 'auto',
            overflow: 'hidden',
          },
        )
        gsap.from(
          node,
          {
            height: 0,
            duration: 0.3,
            ease: 'power4.out',
            clearProps: 'height',
            onComplete: done,
          },
        )
      }
    }
    onExit={
      ( node, done ) => {
        gsap.set(
          node,
          { overflow: 'hidden' },
        );
        gsap.to(
          node,
          {
            height: 0,
            duration: 0.3,
            ease: 'power4.out',
            onComplete: done,
          },
        )
      }
    }
  >
    {props.children}
  </Transition>
);
export default TransitionHeight
