import React, { useState } from 'react'
import classNames from "classnames"

import AccordionButton from './AccordionButton'
import AccordionPanel from './AccordionPanel'

const Accordion = (props) => {
  const {
    sections
  } = props

  const [currentPanel, setPanel] = useState('')

  const handleClick = (panelId) => {
    if ( panelId === currentPanel ) setPanel('')
    else setPanel(panelId)
  }

  return (
    <ul className="py-20">
      {sections.map((item, key) => {
        return (
          <li key={key} className="the-header__menu-section d-block leading-1">
            <AccordionButton
              controls={`menu-${key}`}
              expanded={currentPanel === `menu-${key}` ? true : false}
              handleClick={() => handleClick(`menu-${key}`)}
            >
              {item.title}
              <svg className="the-header__accordion-icon w-36 ml-8 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41z" fill="currentColor"></path></svg>
            </AccordionButton>
            <AccordionPanel
              id={`menu-${key}`}
              isExpanded={currentPanel === `menu-${key}` ? true : false}
            >
              <ul className="pt-8 pb-16 px-20 text-20">
                {item.links.map((link_item, index) => {
                  const item = link_item.link
                  let url = item.url.replace(/^(http|https):\/\/(stagewp|www).westernu.edu/i, '' )
                  if ( url.indexOf('http') === -1 ) url = 'https://www.westernu.edu' + url
                  
                  return (
                    <li key={index} className="d-block text-left">
                      <a 
                        className="inline-block py-6 hover:text-yellow transition-colors duration-100 ease-linear" 
                        href={url}
                        target={item.target || null} 
                        rel="noopener"
                      >{item.title}</a>
                    </li>
                  )
                })}
              </ul>
            </AccordionPanel>
          </li>
        )
      })}
    </ul>
  )
}
export default Accordion
