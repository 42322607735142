import React, { useState } from 'react'
import classNames from "classnames"

const HeaderSearch = (props) => {
  const {
    searchUrl,
    facultyUrl,
  } = props

  const defaultEndpoint = 'https://www.westernu.edu/'
  const modes = [
    { mode: 'all', label: 'Search Anything', endpoint: searchUrl || defaultEndpoint },
    { mode: 'faculty', label: 'Faculty & Staff', endpoint: facultyUrl || defaultEndpoint},
  ]

  const [activeMode, setActiveMode] = useState('all')

  const activeAction = () => {
    const { endpoint } = modes.find(({ mode }) => mode === activeMode)
    return endpoint
  }
  const onChangeMode = (e) => {
    setActiveMode(e.currentTarget.value)
  }

  return (
    <div className="mt-16 sm:mt-32">
      <div className="flex flex-col sm:flex-row sm:justify-start sm:items-center sm:space-x-40">
        {modes.map((item, key) => {
          return(
            <label
              key={`search-mode-${item.mode}`}
              className="flex mt-16 sm:mt-0 justify-start items-center text-white hover:text-yellow transtion-colors duration-100 ease-linear cursor-pointer"
            >
              <input
                type="radio"
                className="sr-only"
                value={item.mode}
                checked={item.mode === activeMode} 
                onChange={onChangeMode}
              />
              <span
                className={classNames(
                  'd-block', 'w-24', 'h-24', 'border-1', 'border-white',
                  'rounded-full', 'transtion-colors', 'duration-100',
                  'ease-linear', 
                  { 'bg-yellow border-yellow': activeMode === item.mode }
                )}
              />
              <span
                className={classNames(
                  'd-block', 'ml-12', 'font-bold', 'text-18', 
                  'sm:text-14', 'leading-1', 'tracking-10'
                )}
              >{item.label}</span>
            </label>
          )
        })}
      </div>
      <form
        className="mt-28"
        method="GET"
        action={activeAction()}
      >
        <label className="relative w-full">
          <span className="sr-only">Search</span>
          <input
            type="text"
            name="q"
            placeholder="Search"
            className="w-full py-12 pr-12 px-48 font-sans font-normal text-20 text-black"
          />
          <svg className="absolute top-1/2 left-16 w-20 h-20 text-red transform -translate-y-1/2" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33l-1.42 1.42l-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" fill="currentColor"></path></svg>
        </label>
        <div className="mt-28 text-center">
          <button
            type="submit"
            className="cta-pill cta-pill--red w-full sm:w-240 py-18"
          >Search</button>
        </div>
      </form>
    </div>
  )
}
export default HeaderSearch
