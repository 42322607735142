import React from 'react'
import classNames from "classnames"

import TransitionHeight from './TransitionHeight'

const AccordionPanel = (props) => {
  const {
    children,
    isExpanded,
    id,
  } = props

  return (
    <TransitionHeight isExpanded={isExpanded}>
      {state => (
        <div
          aria-labelledby={`${id}-button`}
          id={id}
          className={classNames({
            'expanded': isExpanded,
            'hidden': !isExpanded && state === 'exited'
          })}
        >
          {children}
        </div>
      )}
    </TransitionHeight>
  )
}
export default AccordionPanel
