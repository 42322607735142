import React, { useState } from 'react'
import classNames from "classnames"

import HeaderMenu from './HeaderMenu'

const Header = (props) => {
  const {
    home_url,
    site_name,
  } = props

  const {
    sections,
    ctas,
    search_heading,
    search_page,
    search_faculty_page,
    search_links,
    network_home_url,
    network_home_theme_url,
  } = props.data

  const [hideMenu, setHideMenu] = useState(false)
  const [menuExpanded, setMenuExpanded] = useState(false)

  return (
    <header className={classNames(
      'the-header', 'w-full', 'h-auto',
      'pt-0', 'font-sans', {
      'the-header--hidden': hideMenu,
      'the-header--expanded': menuExpanded,
    })}>
      <div className="relative flex items-center w-full h-header bg-red text-white">
        <div className="the-header__color-edge"></div>
        <div className="container">
          <div className="flex pl-24 xs:pr-24 justify-between items-center">
            <a href={network_home_url} className="hidden md:block w-288">
              <img className="d-block filter invert" src={`${network_home_theme_url}public/images/logo-westernu-h.svg`} alt="Western University of Health Sciences Logo" crossOrigin="true" />
            </a>
            <a href={network_home_url} className="d-block md:hidden w-176">
              <img className="d-block" src={`${network_home_theme_url}public/images/wordmark.png`} alt="WesternU Logo" crossOrigin="true" />
            </a>
            {home_url && site_name && (
              <h1 className="hidden lg:block px-64 flex-grow text-left">
                <a href={ home_url } className="font-serif font-bold text-20 tracking-20 leading-100">
                  { site_name }
                </a>
              </h1>
            )}
            <HeaderMenu 
              menuExpanded={menuExpanded}
              setMenuExpanded={setMenuExpanded}
              sections={sections}
              ctas={ctas}
              search_heading={search_heading}
              search_page={search_page}
              search_faculty_page={search_faculty_page}
              search_links={search_links}
            />
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
