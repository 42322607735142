import React from "react";
import { Transition } from "react-transition-group";
import gsap from "gsap";

const TransitionMenu = props => (
  <Transition
    timeout={500}
    in={props.menuExpanded}
    onEnter={
      ( node, done ) => {
        const innerContent = node.getElementsByClassName( 'the-header-menu__panel-inner' )
        
        gsap.fromTo(
          node, 
          {
            x: '100%',
          },
          {
            x: 0,
            duration: 0.2,
            ease: 'power2.out',
          },
        )
        gsap.fromTo(
          innerContent,
          {
            alpha: 0,
          },
          {
            alpha: 1,
            duration: 0.5,
            ease: 'power2.out',
            delay: 0.1,
            onComplete: done,
          },
        )
      }
    }
    onExit={
      ( node, done ) => {
        gsap.to(
          node,
          {
            x: '100%',
            duration: 0.15,
            ease: 'power1.out',
            delay: 0,
            onComplete: done,
          },
        )
      }
    }
  >
    {props.children}
  </Transition>
);
export default TransitionMenu
