import React, { useState } from 'react'

const AccordionButton = (props) => {
  const {
    children,
    controls,
    expanded,
    handleClick,
  } = props

  return (
    <button className="the-header__accordion-button flex w-full justify-between items-center py-10 text-28 font-bold fill-current hover:text-yellow transition-colors duration-100 ease-linear focus-light"
      id={`${controls}-button`}
      aria-expanded={expanded.toString()}
      aria-controls={controls}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}
export default AccordionButton
