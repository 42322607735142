import React, { useState, useEffect } from 'react'
import classNames from "classnames"

import Accordion from './Accordion'
import HeaderSearch from './HeaderSearch'
import TransitionMenu from './TransitionMenu'

const HeaderMenu = (props) => {
  const {
    menuExpanded,
    setMenuExpanded,
    sections,
    ctas,
    search_heading,
    search_page,
    search_faculty_page,
    search_links,
  } = props

  useEffect( () => {
    const onKeyDown = (({key}) => {
      // console.log('key down: ', key)
      if ( key === 'Escape' ) closeMenu() //console.log('Escape is clicked')
    })

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  // const [hideMenu, setHideMenu] = useState(false)
  const [mode, setMode] = useState(undefined)

  const toggleMenu = (mode) => {
    setMode(mode)
    setMenuExpanded(!menuExpanded)
  }
  const closeMenu = () => {
    setMenuExpanded(false)
  }

  return (
    <div className={classNames('the-header-menu', `the-header-menu--${mode}`)}>
      <div className="flex">
        <button className={classNames(
          'the-header-menu__button', 'bg-red-medium',
          { 'the-header-menu__button--expanded' : menuExpanded },
        )}
          aria-expanded={menuExpanded && mode === 'search'}
          disabled={menuExpanded && mode !== 'search'}
          onClick={() => toggleMenu('search')}
        >
          <svg className="w-24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33l-1.42 1.42l-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" fill="currentColor"></path></svg>
          <span className="sr-only">Search</span>
        </button>
        <button className={classNames(
          'the-header-menu__button', 'bg-red-dark',
          { 'the-header-menu__button--expanded' : menuExpanded }
        )}
          aria-expanded={menuExpanded && mode === 'nav'}
          disabled={menuExpanded && mode !== 'nav'}
          onClick={() => toggleMenu('nav')}
        >
          <svg className="w-32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.309 9" aria-hidden="true"><path d="M0,0H29.309V2H0ZM0,9H29.309V7H0Z" fill="currentColor"/></svg>
          <span className="d-block mt-6 text-10 uppercase font-extrabold text-center leading-100 tracking-100">
            Menu
          </span>
        </button>
      </div>
      <TransitionMenu menuExpanded={menuExpanded}>
        {state => (
          <div className={classNames(
            'the-header-menu__panel',
            {'hidden': !menuExpanded && state === 'exited'}
            )}>
            <button className="the-header-menu__panel-close"
              onClick={closeMenu}
            >
              <div className="flex flex-col justify-center items-center">
                <svg className="w-24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.138 19" aria-hidden="true"><path d="M12.483,9.5l9.655,8.286L20.724,19l-9.655-8.286L1.414,19,0,17.786,9.655,9.5,0,1.214,1.414,0l9.655,8.286L20.724,0l1.414,1.214Z" fillRule="evenodd" fill="currentColor"/></svg>
                <span className="d-block mt-6 text-10 uppercase font-extrabold text-center leading-100">
                  Close
                </span>
              </div>
            </button>
  
            <div className="the-header-menu__panel-inner px-8 lg:px-4 pb-64 my-10 lg:my-0 mx-auto lg:mx-0">
              <div className="the-header-menu__panel-content the-header-menu__panel-content--nav max-w-384 px-24 mx-auto">
  
                <div className="the-header__nav">
                  <nav aria-label="header-menu" title="header-menu">
                    <Accordion 
                      sections={sections}
                    />
                  </nav>
                  {ctas && (
                    <ul>
                      {ctas.map((link_item, key) => {
                        const item = link_item.link
                        let url = item.url.replace(/^(http|https):\/\/(stagewp|www).westernu.edu/i, '' )
                        if ( url.indexOf('http') === -1 ) url = 'https://www.westernu.edu' + url
  
                        return (
                          <li key={key} className="d-block mt-20">
                            <a 
                              className="cta-outline cta-outline--white d-block w-full" 
                              href={url}
                              target={item.target || null} 
                              rel="noopener"
                            >{item.title}</a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </div>
  
              <div className="the-header-menu__panel-content the-header-menu__panel-content--search px-24 md:px-48 py-64">
                <h2 className="fonts-sans font-bold text-32 leading-130 text-left text-white">
                  {search_heading || 'Search'}
                </h2>
  
                <HeaderSearch 
                  searchUrl={search_page}
                  facultyUrl={search_faculty_page}
                />
  
                {search_links && (
                  <ul className="mt-32">
                    {search_links.map((link_item, key) => {
                      const item = link_item.link
                      let url = item.url.replace(/^(http|https):\/\/(stagewp|www).westernu.edu/i, '' )
                      if ( url.indexOf('http') === -1 ) url = 'https://www.westernu.edu' + url
  
                      return (
                        <li key={key} className="d-block mt-16 text-center">
                          <a className={classNames(
                            'cta-arrow',
                            'cta-arrow--footer'
                            )}
                            href={url}
                            target={item.target || null}
                            rel="noopener"
                          >{item.title}
                            <svg className="cta-arrow__icon d-block w-16 ml-12" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg"><polygon fill="currentColor" points="11.3168217 0 9.27266614 2.39591605 14.0042587 6.6137266 8.92524936 6.42314237 0 6.42314237 0 9.57685763 8.92524936 9.57685763 14.0042587 9.3862734 9.19197579 13.6063528 11.3168217 16 20 8"></polygon></svg>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
  
            </div>
  
          </div>
        )}
      </TransitionMenu>
	  </div>
  )
}
export default HeaderMenu
