{
  "data":{
    "network_home_url":"https:\/\/www.westernu.edu\/",
    "network_home_theme_url":"https:\/\/www.westernu.edu\/wp-content\/themes\/westernu\/",
    "sections":[
      {
        "title":"Admissions & Aid",
        "links":[
          {
            "link":{
              "title":"Admissions",
              "url":"https:\/\/www.westernu.edu\/admissions\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Financial Aid & Cost",
              "url":"https:\/\/www.westernu.edu\/financial\/",
              "target":"_blank"
            }
          }
        ]
      },
      {
        "title":"Colleges & Programs",
        "links":[
          {
            "link":{
              "title":"Colleges",
              "url":"https:\/\/www.westernu.edu\/colleges\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Academic Programs",
              "url":"https:\/\/www.westernu.edu\/academic-programs\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"WesternU Online",
              "url":"https:\/\/www.westernu.edu\/westernu-online\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Academic Catalog",
              "url":"https:\/\/www.westernu.edu\/registrar\/registrar-about\/university-catalog\/",
              "target":""
            }
          }
        ]
      },
      {
        "title":"Research",
        "links":[
          {
            "link":{
              "title":"Funding",
              "url":"\/research\/about\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Research Committees",
              "url":"\/research\/research-committees\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Library",
              "url":"https:\/\/www.westernu.edu\/library\/",
              "target":"_blank"
            }
          },
          {
            "link":{
              "title":"Contact",
              "url":"\/research\/research-contact\/",
              "target":""
            }
          }
        ]
      },
      {
        "title":"Student Experience",
        "links":[
          {
            "link":{
              "title":"For Current Students",
              "url":"\/student-experience\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Clubs & Recreation",
              "url":"\/students\/westernu-clubs-1\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Student Services",
              "url":"\/students\/students-services\/students-new-services\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Alumni Services",
              "url":"https:\/\/alumnifriends.westernu.edu\/student-services",
              "target":"_blank"
            }
          }
        ]
      },
      {
        "title":"About Us",
        "links":[
          {
            "link":{
              "title":"Who We Are",
              "url":"\/university\/about\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Fast Facts",
              "url":"\/publicaffairs\/news\/fact-sheet\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"History",
              "url":"\/university\/about\/history\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Administrative Offices",
              "url":"\/university\/introduction\/administrative-offices\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Campus Maps",
              "url":"\/campus\/campus-directions\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Contact Us",
              "url":"\/university\/contact-us\/",
              "target":""
            }
          }
        ]
      },
      {
        "title":"Alumni & Friends",
        "links":[
          {
            "link":{
              "title":"WesternU Alumni Association",
              "url":"https:\/\/alumnifriends.westernu.edu\/",
              "target":"_blank"
            }
          },
          {
            "link":{
              "title":" Give to the University",
              "url":"https:\/\/alumnifriends.westernu.edu\/giving\/make-a-gift",
              "target":"_blank"
            }
          }
        ]
      },
      {
        "title":"For Community",
        "links":[
          {
            "link":{
              "title":"California Campus",
              "url":"\/for-community\/california-campus\/",
              "target":""
            }
          },
          {
            "link":{
              "title":"Oregon Campus",
              "url":"\/for-community\/oregon-campus\/",
              "target":""
            }
          }
        ]
      }
    ],
    "ctas":[
      {
        "link":{
          "title":"News",
          "url":"\/news\/",
          "target":""
        }
      },
      {
        "link":{
          "title":"Events Calendar",
          "url":"https:\/\/events.westernu.edu\/",
          "target":"_blank"
        }
      },
      {
        "link":{
          "title":"Campus Directory",
          "url":"https:\/\/jprod.westernu.edu\/directory",
          "target":""
        }
      }
    ],
    "search_heading":"What are you looking for?",
    "search_page":"https:\/\/www.westernu.edu\/search\/",
    "search_faculty_page":"",
    "search_links":[
      {
        "link":{
          "title":"See Listing of All Offices",
          "url":"https:\/\/www.westernu.edu\/university\/introduction\/administrative-offices\/",
          "target":""
        }
      }
    ]
  }
}